.BottomTabs {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 400px;
    background-color: #fff;
    z-index: 1000;
}

.BottomTabs.BottomTabs_rtl {
    left: auto !important;
    right: 0 !important;
}

@media screen and (max-width:721px) {
    .BottomTabs {
        bottom: 0;
        left: 0;
        width: 100%
    }
}