@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@400;700&display=swap');

:root {
  --backgroundColor : #f8f8f8;
}

body {
  margin: 0;
}

html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

/* #app {
  background-color: #000;
} */

#app__wrap {
  width: 400px;
  overflow: auto;
  height: 100vh;

}

#app__inner {
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  overflow-y: auto;
}

#app_outer {
  background-color: #000;
}

.p-16 {
  padding: 16px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.h-100 {
  height: 100%;
}

.max_h-100 {
  min-height: 100%;
}

.MuiDialog-root .MuiPaper-root {
  width: calc(400px - 65px);
}

.MuiDialog-container {
  justify-content: start !important;
}

/* google maps */

.pac-container *	 {
  font-size: 16px;
  font-family: Vazirmatn;
}

.pac-container .pac-item-query	{
  display: block;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
}

.pac-item	{
  padding-left: 30px;
  position: relative;
  padding-top: 7px;
  padding-bottom: 7px;
}

.pac-icon {
  position: absolute;
}



/* skeleton */

.skeleton {
  background: #eee linear-gradient(90deg, #acacac55, transparent 61%);
  overflow: hidden;
  position: relative;
  color: transparent;
}

.skeleton:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0 !important;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background-color: #999 !important;
  height: 5px;
  margin: 2px !important;
}

.swiper-pagination-clickable .swiper-pagination-bullet-active {
  width:15px;
  border-radius: 5px;
}

@media screen and (max-width:721px) {
  #app__wrap {
    width: 100%;
    /* height: auto; */
  }

  .MuiDialog-root .MuiPaper-root {
    width: 100%;
  }
}