.CategoriesBar {
    padding: 16px;
}

.CatergoriesBar__item {
    width:auto;
    cursor: pointer;
}

.CatergoriesBar__itemBox {
    border-radius: 50px;
    padding: 5px 15px;
    display: block;
    text-decoration: none;
}

