.Product_image {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.ProductDetailsPage_content {
    position: relative;
    z-index: 10;
}

.ProductDetailsPage_notch {
    width:100%;
    position: absolute;
    top:0;
    left: 0;
    height: 15px;
    border-radius: 50px 50px 0 0;
    transform: translateY(-95%);
    z-index: 1;
    background-color: var(--backgroundColor);
}

.appBar {
    left: 0;
    width: 400px;
}

.appBar.appBar_rtl {
    left: auto !important;
    right: 0 !important;
}



.BottomTabs {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 400px;
    background-color: #fff !important;
    z-index: 100;
}

.BottomTabs.BottomTabs_rtl {
    left: auto !important;
    right: 0 !important;
}


@media screen and (max-width:721px) {

    .appBar {
        left: 0;
        width: 100%
    }

    .BottomTabs {
        bottom: 0;
        left: 0;
        width: 100%
    }
}