.ProductItem {
    margin-bottom: 15px;
}

.image {
    height: 100%;
    background: #eee linear-gradient(0deg, #acacac, transparent 61%);
}

.title {
    height: 16px;
    width:50%;
    background: #eee linear-gradient(0deg, #acacac88, transparent 61%);
    margin-bottom: 15px;
}

.desc > span {
    height: 12px;
    display: block;
    margin-bottom: 5px;
    background: #eee linear-gradient(0deg, #acacac88, transparent 61%);
}

.desc > span:nth-child(1) {
    width:60%;
}

.desc > span:nth-child(2) {
    width:80%;
}

.desc > span:nth-child(3) {
    width:50%;
   
}

.desc > span:last-of-type {
    margin-bottom: 15px;
}

.price {
    background: #eee linear-gradient(0deg, #acacac88, transparent 61%);
    height: 18px;
    width:15%;
}