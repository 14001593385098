.page_container {
    position: relative;
}

.page_container > div {
    position: relative;
}

.page_container_overlay {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: block;
    background-color:#24242478;
    z-index: 0;
}

.intro_brand__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 30px;
}

.intro_brand__logo--img {
    border-radius: 100%;
    max-width: 100%;
    width: 190px;
    aspect-ratio: 1;
}

.intro_brand__label {
    text-align: center;
    color: #07485B ;
    font-size: 36px;
    font-weight: bold;
}

.intro__logo--placeholder {
    background-color: #000;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    height: 150px;
    width: 150px;
}

@media screen and (min-width:722px) {
    .page_container {
       background-image: none !important;
    }

    .visible_mobile{
        display: none !important;
    }
    .visible_desktop {
        display: block !important;
    }

    .page_container_overlay {
        display: none;
    }

}

@media screen and (max-width:721px) {
    .visible_mobile {
        display: block !important;
    }
    .visible_desktop {
        display: none !important;
    }
}