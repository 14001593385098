.orders-skeleton__item {
    margin: 0 7px 16px;
    border-radius: 10px;
    padding: 16px;
    position: relative;

}

.orders-skeleton__wrapper {
    position: relative;
    padding: 16px 7px;
}



.text-skeleton {
    height: 14px;
    margin-bottom: 10px;
}

.text-skeleton:nth-child(1) {
    width:50%;
}

.text-skeleton:nth-child(2) {
    width:80%;
}

.text-skeleton:nth-child(3) {
    width:40%;
}



.btn-skeleton {
    height: 36px;
    border-radius: 5px;
}

@media screen and (max-width:767px) {}