.delivery_option--selector {
    display: flex;
    margin-bottom:16px;
    border-radius: 50px;
}
.delivery_option {
    border-radius: 50px;
    padding:10px 20px;
    flex:1;
    text-align: center;
}

.delivery_option input {
    display: none;
}

.delivery_option label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}



.delivery_option input:checked + label {
    color: #fff;
}

.delivery_option--selected {
   
}