.ProductItem {
    margin-bottom: 10px;
}

.ProductItem_image {
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
}

.ProductItem_no_image {
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    filter: grayscale(1);
    opacity: 0.3;
}


.ProductItem_icon {
    border-radius: 50px 50px 0 50px;
    aspect-ratio: 1;
    position: absolute;
    bottom: 6px;
}

.ProductItem_description {
    min-height: 36px;
}