.map {
    position: relative;
}

.map:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%; left: 50%;
    margin: -40px 0 0 -11px;
    background: url('../../assets/images/map_marker.png');
    background-size: 22px 40px; /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none; /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}


.FormControl {
    left: 0;
    width: calc(400px) !important;
    right: auto !important;
    position: absolute !important;
    z-index: 1000;
    background-color: #fff;
}

.FormControl.FormControl_rtl {
    left: auto;
    right: 0;
}

.DeliveryLocation {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 400px;
    padding: 16px;
    background-color: '#fff';
}

@media screen and (max-width:721px) {
    
    .FormControl , .DeliveryLocation {
        width: 100%  !important;
    }
}