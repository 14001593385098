.image-skeleton {
    height: 90px;
    
}

.title-skeleton {
    height: 24px;
    width:50%;
    margin-bottom: 15px;
}

.description-skeleton > span {
    height: 15px;
    display: block;
    margin-bottom: 5px;
}

.description-skeleton > span:nth-child(1) {
    width:60%;
}

.description-skeleton > span:nth-child(2) {
    width:80%;
}

.description-skeleton > span:nth-child(3) {
    width:50%;
    margin-bottom: 15px;
}