.AppBar {
    left: 0;
    width: 400px !important;
    right: auto !important;
}

.AppBar.AppBar_rtl {
    left: auto !important;
    right: 0 !important;
}

.AppBar_floating {
    position: fixed;
    top: 0;
    left: 0;
    width: 400px;
    z-index: 100000;
}

.cartBadge {
    height: 5px;
    width: 5px;
    background-color: #f00;
    position: absolute;
    top:0;
    right: 5px;
    border-radius: 10px;
}

@media screen and (max-width:721px) { 
    .AppBar {
        width: 100% !important;
    }
}