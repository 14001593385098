.cat__item {
    text-align: center;
    color:inherit;
    text-decoration: none;
}

.cat__item img {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.cat__item--title {
    text-align: center;
    font-size: 12px;
}

.cat__item a {
    text-decoration: none;
    color: inherit;
}

.cat__item--img {
    margin-bottom: 10px;
    height: 50px;
    width: auto;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 7px;
}

.cat_item_rounded {
    border-radius: 50px !important;
}