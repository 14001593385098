
.Banner {
    padding-bottom: 15px;
}

.Banner .swiper-pagination {
    bottom: 0 !important;
}

.Banner__item img {
    width:100%;
    max-width: 100%;
    height: 100px;
    object-fit: cover;
}


.Banner,.Banner__item {
    overflow: hidden;
}

.Banner__item_multi {
    width: 70% !important;
}