.banner-skeleton__item {
    height: 175px;
    margin: 0 7px;
    flex-shrink: 0;
    border-radius: 10px;
}

.banner-skeleton {
    overflow: hidden;
}

.banner-skeleton__wrapper {
    position: relative;
    padding: 0 7px;
}

@media screen and (max-width:767px) {}