.CategoriesBar-skeleton {
    display: flex;
    flex-direction: row;
    padding: 16px 7px;
}

.CategoriesBar-skeleton__item {
    height:25px;
    width: 300px;
    flex:1 1 auto;
     margin: 0 5px;
    border-radius: 10px;
}