.SectionTile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.SectionTile__title {
    color: #172327;
}

.SectionTile__button {
    color: #172327;
     font-size: 10px;
}