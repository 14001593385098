.categories-skeleton__item {
    width: 29%;
    margin: 0 7px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}


.categories-skeleton__title {
    margin-top: 10px;
    height: 12px;
    width: 70%;
    display: block;
    margin-bottom: 10px;

}

.categories-skeleton__img {
    width: 100%;
    border-radius: 15px;
    display: block;
    overflow: hidden;
    aspect-ratio: 1;
}

.categories-skeleton {
    overflow: hidden;
}

.categories-skeleton__wrapper {
    display: flex;
    width: 100vw;
    position: relative;
    padding: 0 7px;
}

.categories-skeleton__wrapper--grid {
    display:flex;
    flex-wrap: wrap;
    width:100%;
}

@media screen and (max-width:767px) {}