
.brand__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 30px;
}

.brand__logo--img {
    border-radius: 100%;
    max-width: 100%;
    width: 190px;
    aspect-ratio: 1;
}

.brand__label {
    text-align: center;
    color: #07485B ;
    font-size: 36px;
    font-weight: bold;
}

.brand__logo--img_sm {
    width: 150px;
    height: 150px;
}

.brand__label--sm {
    font-size: 22px;
}

.brand__logo--placeholder {
    background-color: #000;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
}