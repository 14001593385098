.CategoriesCarousel {
    padding-left: 16px;
    padding-right: 16px;
    overflow: hidden;
}

.cat__item {
    width: 75px !important;
    text-align: center;
}

.cat__item img {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.cat__item--title {
    text-align: center;
    font-size: 12px;
}

.cat__item a {
    text-decoration: none;
    color: inherit;
}

.cat__item--img {
    margin-bottom: 10px;
    height:50px;
    width: auto;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50px;
}